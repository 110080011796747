import { useState } from "react";
import "./styles/contact_form.css";

const ContactForm = () => {
    const [name, set_name] = useState("");
    const [surname, set_surname] = useState("");
    const [phone, set_phone] = useState("");
    const [email, set_email] = useState("");
    const [restaurant, set_restaurant] = useState("");
    const [address, set_address] = useState("");
    const [description, set_description] = useState("");
    const [warning, set_warning] = useState("");

    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, "");
        if (cleaned.length == 0)
            return "";
        else if (cleaned.length <= 3)
            return cleaned;
        else if (cleaned.length <= 6)
            return `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`;
        else if (cleaned.length <= 8)
            return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
        else
            return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6, 8)} ${cleaned.slice(8, 10)}`;
    };

    const handlePhoneChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        set_phone(formattedPhone);
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^5\d{2} \d{3} \d{2} \d{2}$/;
        return phoneRegex.test(phone);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = () => {
        if (!validatePhoneNumber(phone)) {
            set_warning("Telefon numarası formatı hatalıdır. Lütfen 5xx xxx xx xx formatında giriniz.");
            return;
        }
        if (!validateEmail(email)) {
            set_warning("Geçerli bir email adresi giriniz.");
            return;
        }
        if (!restaurant) {
            set_warning("Restoran İsmi zorunludur.");
            return;
        }

        // Form bilgilerini topla
        const formData = {
            name,
            surname,
            phone,
            email,
            restaurant,
            address,
            description,
        };

        console.log("Form Data:", formData);

        // Hata yoksa, form gönderimini yap
        set_warning("");
        alert("Form başarıyla gönderildi!");
    };

    return (
        <>
            <div className="contact_form_row">
                <input
                    type="text"
                    id="name"
                    placeholder="İsim"
                    value={name}
                    onChange={(e) => set_name(e.target.value)}
                />
                <input
                    type="text"
                    id="surname"
                    placeholder="Soyisim"
                    value={surname}
                    onChange={(e) => set_surname(e.target.value)}
                />
            </div>
            <div className="contact_form_row">
                <input
                    type="tel"
                    id="phone"
                    placeholder="*5xx xxx xx xx"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                />
                <input
                    type="email"
                    id="email"
                    placeholder="*Email"
                    value={email}
                    onChange={(e) => set_email(e.target.value)}
                    required
                />
            </div>
            <div className="contact_form_row">
                <input
                    type="text"
                    id="restaurant"
                    placeholder="*Restoran İsmi"
                    value={restaurant}
                    onChange={(e) => set_restaurant(e.target.value)}
                    required
                />
            </div>
            <div className="contact_form_row">
                <input
                    type="text"
                    id="address"
                    placeholder="Adres"
                    value={address}
                    onChange={(e) => set_address(e.target.value)}
                />
            </div>
            <div className="contact_form_row">
                <textarea
                    id="description"
                    placeholder="Eklemek istediğiniz bir şeyler var mı ?"
                    rows={5}
                    value={description}
                    onChange={(e) => set_description(e.target.value)}
                />
            </div>
            <p style={{ color: "red" }}>{warning}</p>
            <div className="submit_button" onClick={handleSubmit}>
                Gönder
            </div>
        </>
    );
};

export default ContactForm;
