import "./styles/sliding_list_card.css";

const SlidingListCard = ({ data }) => {
    return (
        <a href={data.link} target="_blank" className="sliding_list_card">
            <div className="card_background_cover" style={{backgroundImage: `url(${data.background})`}}>
                <h4>{data.title}</h4>
                <p>{data.description}</p>
            </div>
        </a>
    );
};

export default SlidingListCard;