import {useState, useEffect, useRef} from "react";

import "./styles/soon_page.css";
import SlidingListCard from "../components/sliding_list_card";
import ContactForm from "../components/contact_form";

import intro_image from "../public/background.jpg";
import logo from "../public/logo.png";
import qr_background from "../public/QR-background.jpg";
import serving_background from "../public/serving.png";
import black_menumenum from "../public/black_menumenum.jpg";
import waiter_background from "../public/waiter.png";

const SoonPage = () => {   
    const iconic_description_data = [
        { title: "Hızlı, efektif müşteri-garson etkileşimi", description: "Hızlı, efektif müşteri-garson etkileşimi", background: serving_background },
        { title: "QR ödeme altyapısı ile hızlı güvenilir entegre ödeme sistemleri", description: "QR ödeme altyapısı ile hızlı güvenilir entegre ödeme sistemleri", background: qr_background },
    ];
    const [iconic_description_part_index, set_iconic_description_part_index] = useState(0);

    const [chronometer, setChronometer] = 
        useState({  years: 0,
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0});
    const [email, setEmail] = useState("");

    const card_info_data = [
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
        { title: "İşlerinizi 25% oranında maliyet düşüşü", description: "Qr kodlu ödeme entegresi sayesinde süreç hızlanır ve iş yükü azalır", link: "", background: waiter_background },
    ];
    const sliding_list_content_ref = useRef(null);

    const sliding_list_scrollLeft = () => {
        sliding_list_content_ref.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const sliding_list_scrollRight = () => {
        sliding_list_content_ref.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    useEffect(() => {
        const chronometerIntervalId = setInterval(() => {
            setChronometer(calculate_remaining_time());
        }, 1000);

        const descriptionIntervalId = setInterval(() => {
            set_iconic_description_part_index((prevIndex) => (prevIndex + 1) % iconic_description_data.length);
        }, 5000);

        return () => {
            clearInterval(chronometerIntervalId);
            clearInterval(descriptionIntervalId);
        };
    }, []);

    const calculate_remaining_time = () => {
        const release_date = new Date(2024,10,12,0,0,0);
        const now = new Date()

        let diff = Math.abs(release_date - now);

        const seconds = Math.floor((diff / 1000) % 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));

        const years = Math.floor(days / 365);
        const months = Math.floor((days % 365) / 30);
        const remainingDays = Math.floor((days % 365) % 30);

        return {
            years: years,
            months: months,
            days: remainingDays,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    return (
        <>
            <div className="soon_page_div">
                <div className="introduction_part background_cover attached_section_background" style={{backgroundImage: `url(${intro_image})`}}>
                    <div className="introduction_content">
                        <div className="logo background_contain" style={{backgroundImage: `url(${logo})`}}></div>
                        <h3>Menumenum Yakında !</h3>
                    </div>
                </div>

                <div className="chronometer_part">
                    <div className="chronometer_part_wrapper">
                        <div className="chronometer">{chronometer.years} Yıl {chronometer.months} Ay {chronometer.days} Gün {chronometer.hours} Saat {chronometer.minutes} Dakika {chronometer.seconds} Saniye</div>
                    </div>
                </div>

                <div className="iconic_description_part"> {/* TODO, change of the text shrinks the page, fix css*/}
                    <div className="iconic_description_panel_left">
                        <h3>{iconic_description_data[iconic_description_part_index].title}</h3>
                        <p>{iconic_description_data[iconic_description_part_index].description}</p>
                        <button onClick={() => {
                            const contactPart = document.getElementById('contact_part');
                            if (contactPart) {
                                contactPart.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}>Detaylı Bilgi</button>
                    </div>
                    <div className="iconic_description_panel_right background_cover" style={{backgroundImage: `url(${iconic_description_data[iconic_description_part_index].background})`}}></div>
                </div>

                <div className="sliding_list_part">
                    <div className="sliding_list_content" ref={sliding_list_content_ref}>
                        {card_info_data.map((data, index) => (
                            <SlidingListCard data={data} key={index}/>
                        ))}
                    </div>
                    <div className="sliding_list_controller_cover">
                        <div onClick={sliding_list_scrollLeft}>{"<"}</div>
                        <div onClick={sliding_list_scrollRight}>{">"}</div>
                    </div>
                </div>

                <div id="contact_part">
                    <div className="left_contact_panel">
                        <h3>İşletmenizi <span style={{color: "#FF6666"}}>Menumenum</span> ile güçlendirin.</h3>
                    </div>
                    <div className="right_contact_panel">
                        <p>İşletmenizi Menümenüm ile nasıl güçlendirebileceğinizin detaylarına bizimle iletişime geçerek ulaşabilirsiniz</p>
                        <ContactForm />
                    </div>
                </div> 

                <div>
                    
                </div>
            </div>
            <div className="black_menumenum_part background_cover" style={{backgroundImage: `url(${black_menumenum})`}}></div>
        </>
    );
};

export default SoonPage;