import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SoonPage from './pages/soon_page';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SoonPage />} />

        {/* Catch-all route for unmatched paths (404) */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
